<template>
  <div class="enterprise-certification">
    <AppTopNav></AppTopNav>
    <AppLayoutCommon>
      <AppSteps :current="nowStep">
        <AppStepsItem
          v-for="(item, index) in steps"
          :key="item.stepNumber"
          :stepNumber="item.stepNumber"
          :stepText="item.stepText"
          :nowStep="nowStep"
          :showArrow = "index !== steps.length - 1"
        ></AppStepsItem>
      </AppSteps>
      <div class="form-area">
        <Form ref="formValidate" :label-width="150">
          <Step1
            v-show="nowStep === 0"
            ref="Step1RefChilde"
            :fileLimitSize="fileLimitSize"
            class="step1"
            :companyInfo="companyInfo"
          />
          <Step2
            v-show="nowStep === 1"
            ref="step2RefChilde"
            :fileLimitSize="fileLimitSize"
            :companyInfo="companyInfo"
          />
          <div v-if="nowStep === 2" class="step3">
            <img :src="require('/static/icons/warn.png')" alt="" />
            <span class="secondary-info">认证信息已提交，正在审核中</span>
            <span>
              您的资料已提交成功，我们将会在2-5个工作日与您电话联系，请您保持电话畅通，谢谢。
            </span>
            <router-link to="/">返回首页</router-link>
          </div>
          <div v-if="nowStep === 3  && !failed" class="step4">
            <img :src="require('/static/icons/success.png')" alt="" />
            <span class="secondary-info">认证成功</span>
            <span>亲爱的用户，您已注册并审核通过！</span>
            <router-link to="/">返回首页</router-link>
          </div>
          <div v-if="nowStep === 3 && failed" class="step4">
            <img :src="require('/static/icons/error.png')" alt="" />
            <span class="secondary-info">审核未通过</span>
            <span>您的资料审核未通过，请核实后重新认证</span>
            <router-link to="" @click="reauthentication">重新认证</router-link>
          </div>
          <FormItem>
            <Button
              v-if="nowStep === 1"
              style="width: 130px; margin-right: 30px"
              @click="toPre"
            >
              上一步
            </Button>
            <Button
              v-if="nowStep < 2"
              class="commit-btn"
              style="width: 130px"
              :disabled="nextButtonDidabled"
			        @click="toNext"
            >
              下一步
            </Button>
          </FormItem>
        </Form>
      </div>
    </AppLayoutCommon>
    <AppFooter />
  </div>
</template>

<script>
import AppLayoutCommon from '@/components/AppLayoutCommon'
import AppFooter from '@/components/AppFooter'
import AppTopNav from '@/components/AppTopNav'
import { setUserIdentifyInfo, getCompanyInfo } from '@/api/user.js'
import { Step1, Step2 } from './component/Index'
import { ref } from 'vue'
export default {
  name: 'CompanyIdentification',
  data () {
    return {
      steps: [
        {
          stepNumber: 1,
          stepText: '基本信息',
          isComplete: false,
        },
        {
          stepNumber: 2,
          stepText: '资料上传',
          isComplete: false,
        },
        {
          stepNumber: 3,
          stepText: '企业信息审核',
          isComplete: false,
        },
        {
          stepNumber: 4,
          stepText: '认证完成',
          isComplete: false,
        },
      ],
      nowStep: 0,
      fileLimitSize: 1048576, // 单位：B
      params: {}, // 所有表单数据
      nextButtonDidabled: false,
      failed: false, // 认证失败
      companyId: null,
      companyInfo: {}, // 获取的提交信息
      status: 0
    }
  },
  setup () {
    const Step1RefChilde = ref()
    const step2RefChilde = ref()
    const step1IsValidate = () => {
      return Step1RefChilde.value.isValidate()
    }
    const getStep1FormData = () => {
      return Step1RefChilde.value.getFormData()
    }
    const step2IsValidate = () => {
      return step2RefChilde.value.isValidate()
    }
    const getStep2FormData = () => {
      return step2RefChilde.value.getFormData()
    }
    return {
      Step1RefChilde,
      step2RefChilde,
      step1IsValidate,
      getStep1FormData,
      step2IsValidate,
      getStep2FormData,
    }
  },
  created () {
    this.status = this.$store.state.login.userInfo.status
    this.companyId = this.$store.state.login.userInfo.companyId
    if (this.status === 2) { // 待认证
      this.nowStep = 2
    } else if (this.status === 3) { // 认证失败
      this.nowStep = 3
      this.failed = true
      this.getCommitedInfo()
    } else if (this.status >= 4) { // 已认证
      this.nowStep = 3
    }

  },
  components: {
    AppLayoutCommon,
    AppTopNav,
    AppFooter,
    Step1,
	  Step2
  },
  beforeRouteUpdate (to) {},
  methods: {
    /* 获取之前提交的数据 */
    getCommitedInfo () {
      const params = {
        companyId: this.companyId
      }
      getCompanyInfo(params).then(res => {
        if (res.returnCode === '1') {
          this.companyInfo = res.result
        }
      })
    },
    async toNext () {
		if (this.nowStep === 0) {
		  	if (!(await this.step1IsValidate())) {
				return
			} else {
				this.asignFormData(this.getStep1FormData())
			}
		} else if (this.nowStep === 1) {
			if (!(await this.step2IsValidate())) {
				return
			} else {
				this.asignFormData(this.getStep2FormData())
			}
			this.$Loading.start()
			this.nextButtonDidabled = true
      this.params.status = this.status
			const result = await setUserIdentifyInfo(this.params)
			if (result.returnCode === '1') {
				this.$Loading.finish()
				this.$Message.success('提交成功')
			} else {
				this.$Loading.error()
				this.nextButtonDidabled = false
				return
			}
		}
		this.nowStep++
    },
    toPre () {
      this.nowStep--
    },
    asignFormData (data) {
      Object.assign(this.params, data)
    },
    reauthentication () {
      this.nowStep = 0
    }
  },
}
</script>

<style lang="less">
.enterprise-certification {
  .step1 {
    a {
      color: #58aaff;
    }
  }

  .form-area {
    padding: 20px 20%;
  }
  .step3,
  .step4 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 400px;
    span,
    a {
      height: 40px;
      line-height: 40px;
    }
    a {
      margin-top: 20px;
      color: #58aaff;
    }
    img {
      width: 60px;
      height: 60px;
    }
  }
}
</style>

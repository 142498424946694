<template>
  <Form
    ref="formValidate"
    :model="formValidate"
    :rules="ruleValidate"
    :label-width="150"
  >
    <PageSection>
      <template v-slot:title>公司信息</template>
      <FormItem label="企业名称" prop="shortName">
        <Input
          v-model="formValidate.shortName"
          placeholder="请输入企业名称"
        ></Input>
      </FormItem>
      <FormItem label="营业执照注册号" prop="taxNo">
        <Input
          v-model="formValidate.taxNo"
          placeholder="请输入营业执照注册号"
        ></Input>
      </FormItem>
      <FormItem label="公司所在地" prop="currentAddress">
        <Input
          v-model="formValidate.currentAddress"
          placeholder="请输入公司所在地"
        ></Input>
      </FormItem>
      <FormItem label="企业邮箱" prop="email">
        <Input
          v-model="formValidate.email"
          placeholder="请输入企业邮箱"
        ></Input>
      </FormItem>
      <FormItem label="公司紧急联系人" prop="emergencyContactPerson">
        <Input
          v-model="formValidate.emergencyContactPerson"
          maxlength="4"
          placeholder="请输入公司紧急联系人"
        ></Input>
      </FormItem>
      <FormItem label="公司紧急联系人电话" prop="emergencyTel">
        <Input
          v-model="formValidate.emergencyTel"
          maxlength="11"
          placeholder="请输入公司紧急联系人电话"
        ></Input>
      </FormItem>
      <!-- <FormItem label="公司logo" prop="logo">
        <Upload
          :before-upload = "logHandleUpload"
          :on-success = "logUploadSuccess"
          :on-remove = "logoUploadRemove"
          accept = "image"
          :multiple="false"
          :data="{ path: 'members' }"
          type="drag"
          action="http://file.qltrade.ixiaoguo.com.cn/file/upload"
          :default-file-list="uploadFile"
        >
          <div style="padding: 20px 0">
            <Icon
              type="ios-cloud-upload"
              size="52"
              style="color: #3399ff"
            ></Icon>
            <p>将文件拖到此处，或点击上传</p>
          </div>
        </Upload>
        <span class="secondary-info">
          图片尺寸请确保800*800px以上，文件大小在6MB以内、支持png，jpeg、gif格式
        </span>
      </FormItem> -->
    </PageSection>
    <PageSection>
      <template v-slot:title>法人信息</template>
      <FormItem label="法人代表证件类型" prop="legalPersonCardType">
        <Select
          v-model="formValidate.legalPersonCardType"
          placeholder="请选择法人代表证件类型"
        >
          <Option :value="1">身份证</Option>
        </Select>
      </FormItem>
      <FormItem label="法人代表证件号" prop="legalPersonCardId">
        <Input
          v-model="formValidate.legalPersonCardId"
          maxlength="18"
          placeholder="请输入法人代表证件号"
        ></Input>
      </FormItem>
      <FormItem label="法人代表名称" prop="legalPersonName">
        <Input
          v-model="formValidate.legalPersonName"
          maxlength="4"
          placeholder="请输入法人代表名称"
        ></Input>
      </FormItem>
      <FormItem label="法人代表联系方式" prop="legalPersonTel">
        <Input
          v-model="formValidate.legalPersonTel"
          maxlength="11"
          placeholder="请输入法人代表联系方式"
        ></Input>
      </FormItem>
      <FormItem prop="isAcceptClause">
        <Checkbox v-model="formValidate.isAcceptClause">
          <span>我已同意</span>
          <a href="javascript:">《齐鲁大宗员注册条款》</a>
        </Checkbox>
      </FormItem>
    </PageSection>
  </Form>
</template>

<script>
// import { fileUploadLimit } from '@/utils/request.js'
import { toRaw } from 'vue'
export default {
  name: 'Step1',
  props: ['fileLimitSize', 'companyInfo'],
  data () {
    const validateShortName = (rule, value, callback) => {
			const title = /[^u4E00-u9FA5]/g
			if (value === '') {
				callback(new Error('企业名称不能为空'))
			}
      if (!title.test(value.trim())) {
        callback(new Error('企业名称格式有误'))
      } else {
        callback()
      }
    }
    return {
      // uploadFile: [],/* 去掉上传logo */
      formValidate: {
        shortName: '',
        taxNo: '',
        currentAddress: '',
        email: '',
        emergencyContactPerson: '',
        emergencyTel: '',
        logo: 'https://file.qiludazong.cn/file/2346186344608373.png',
        legalPersonCardType: '',
        legalPersonCardId: '',
        legalPersonName: '',
        legalPersonTel: '',
        isAcceptClause: '',
      },

      ruleValidate: {
        shortName: [
          // { required: true, message: '企业名称不能为空', trigger: 'blur' },
					{ validator: validateShortName, trigger: 'blur' }
        ],
        taxNo: [
          {
            required: true,
            message: '营业执照注册号不能为空',
            trigger: 'blur',
          },
        ],
        currentAddress: [
          { required: true, message: '公司所在地不能为空', trigger: 'blur' },
        ],
        receivingAddress: [
          { required: true, message: '收货地址不能为空', trigger: 'blur' },
        ],
        email: [
          { required: true, message: '企业邮箱不能为空', trigger: 'blur' },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur' },
        ],
        emergencyContactPerson: [
          {
            required: true,
            message: '公司紧急联系人不能为空',
            trigger: 'blur',
          },
        ],
        emergencyTel: [
          {
            required: true,
            message: '公司紧急联系人电话不能为空',
            trigger: 'blur',
          },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: '紧急联系人手机号格式不正确',
            trigger: 'blur',
          },
        ],
        logo: [{ required: true, message: '公司log不能为空', trigger: 'blur' }],
        legalPersonCardType: [
          {
            required: true,
            message: '法人代表证件类型不能为空',
            trigger: 'change',
            type: 'number',
          },
        ],
        legalPersonCardId: [
          {
            required: true,
            message: '法人代表证件号不能为空',
            trigger: 'blur',
          },
          {
            pattern:
              /(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)/,
            message: '法人代表证件号格式不正确',
            trigger: 'blur',
          },
        ],
        legalPersonName: [
          { required: true, message: '法人代表名称不能为空', trigger: 'blur' },
        ],
        legalPersonTel: [
          {
            required: true,
            message: '法人代表联系方式不能为空',
            trigger: 'blur',
          },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: '法人代表联系方式格式不正确',
            trigger: 'blur',
          },
        ],
        isAcceptClause: [{ required: true, message: '请同意注册条款' }],
      },
    }
  },
  methods: {
    async isValidate () {
      const isValid = await this.$refs.formValidate.validate((valid) => {
        if (!valid) {
          this.$Message.error('请填写必填项!')
        }
      })
      return isValid
    },
    /* 去掉上传logo */
    // logHandleUpload (file) {
    //     if (this.formValidate.logo) {
    //         this.$Message.error('公司log只能上传一张图片，请先删除之前已上传图片')
    //         return false
    //     }
    //     const fileValide = fileUploadLimit(file, this.fileLimitSize)
    //     if (fileValide) {
    //         this.$Message.error(fileValide)
    //         return false
    //     }
    //     return true
    // },
    // logUploadSuccess (response, file, fileList) {
    //     if (response.returnCode === '1') {
    //         this.formValidate.logo = response.result
    //         this.$refs.formValidate.validateField('logo')
    //     }
    // },
    // logoUploadRemove () {
    //     this.formValidate.logo = ''
    //     this.$refs.formValidate.validateField('logo')
    // },
    getFormData () {
      return toRaw(this.formValidate)
    },
  },
  watch: {
    companyInfo (val) {
      this.formValidate = val
      /* 去掉上传logo */
      // this.uploadFile = [{
      //   name: val.logo.split('/').pop(),
      //   url: val.logo
      // }]
      // try {
      //   this.formValidate.logo = this.uploadFile[0].name
      // } catch (e) {
      //   console.error(e)
      // }
    },
  },
}
</script>

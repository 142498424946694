<template>
  <Form
    ref="formValidate"
    :model="formValidate"
    :rules="ruleValidate"
    :label-width="150"
  >
    <FormItem
      label="营业执照/三证合一电子版"
      prop="businessLicense"
      :label-width="200"
    >
      <p>
        请上传清晰营业执照图片，系统识别公司信息自动进行填写，营业执照复印件需加盖公司红章扫描上传，若营业执
        照上未体现注册资本、经营范围，请在营业执照后另行上传企业信息公示网上的截图。
      </p>
      <Upload
        :before-upload = "(file) => handleUpload(file, type.businessLicense)"
        :on-success = "(response, file, fileList) => uploadSuccess( response, file, fileList, type.businessLicense)"
        :on-remove = "(file, fileList) => uploadRemove(file, fileList, type.businessLicense)"
        multiple
        :data="{ path: 'members' }"
        type="drag"
        action="http://file.qltrade.ixiaoguo.com.cn/file/upload"
        :default-file-list="businessLicenseUpload"
      >
        <div style="padding: 20px 0">
          <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
          <p>将文件拖到此处，或点击上传</p>
        </div>
      </Upload>
      <span class="secondary-info">
        图片尺寸请确保800*800px以上，文件大小在6MB以内、支持png，jpeg、gif格式
      </span>
    </FormItem>
    <FormItem
      label="法人证件电子版"
      prop="legalPersonCardUrls"
      :label-width="200"
    >
      <p>
        请按顺序分别上传正面（带有照片一面）和反面电子版图片，复印件请加盖开店公司红章。
      </p>
      <Upload
        :before-upload = "(file) => handleUpload(file, type.legalPersonCardUrl)"
        :on-success = "(response, file, fileList) => uploadSuccess( response, file, fileList, type.legalPersonCardUrl)"
        :on-remove = "(file, fileList) => uploadRemove(file, fileList, type.legalPersonCardUrl)"
        type="drag"
        :data="{ path: 'members' }"
        action="http://file.qltrade.ixiaoguo.com.cn/file/upload"
        :default-file-list="legalPersonCardUpload"
      >
        <div style="padding: 20px 0">
          <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
          <p>将文件拖到此处，或点击上传</p>
        </div>
      </Upload>
      <span class="secondary-info">
        图片尺寸请确保800*800px以上，文件大小在6MB以内、支持png，jpeg、gif格式
      </span>
    </FormItem>
    <!-- <FormItem
      label="银行开户许可电子版"
      prop="legalBankLicenseUrl"
      :label-width="200"
    >
      <p>
        许可证上名称、法人需与营业执照一致，若发生变更须出具变更证明，复印件需加盖公司红章扫描上传
      </p>
      <Upload
        :before-upload = "(file) => handleUpload(file, type.legalBankLicenseUrl)"
        :on-success = "(response, file, fileList) => uploadSuccess( response, file, fileList, type.legalBankLicenseUrl)"
        :on-remove = "(file, fileList) => uploadRemove(file, fileList, type.legalBankLicenseUrl)"
        type="drag"
        action="http://file.qltrade.ixiaoguo.com.cn/file/upload"
      >
        <div style="padding: 20px 0">
          <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
          <p>将文件拖到此处，或点击上传</p>
        </div>
      </Upload>
      <span class="secondary-info">
        图片尺寸请确保800*800px以上，文件大小在6MB以内、支持png，jpeg、gif格式
      </span>
    </FormItem> -->
  </Form>
</template>
<script>
import { fileUploadLimit } from '@/utils/request.js'
import { toRaw } from 'vue'
export default {
  name: 'Step2',
  props: ['fileLimitSize', 'companyInfo'],
  data () {
    return {
      businessLicenseUpload: [],
      legalPersonCardUpload: [],
      formValidate: {
          businessLicense: '', // 营业执照/三证合一电子版
          legalPersonCardUrls: [], // 法人证件正反面上传结果
          // legalBankLicenseUrl: '', // 银行开户许可电子版
          legalPersonCardFrontUrl: '', // 法人证件电子正面
          legalPersonCardBackUrl: ''// 法人证件电子反面
      },
      ruleValidate: {
          businessLicense: [
              { required: true, message: '请上传营业执照/三证合一电子版' },
          ],
          legalPersonCardUrls: [
              { required: true, message: '请上传法人证件电子版', type: 'array' },
          ],
          legalBankLicenseUrl: [
              { required: true, message: '请上传银行开户许可电子版' },
          ],
      },
      type: {
          businessLicense: 1, // 营业执照/三证合一
          legalPersonCardUrl: 2, // 法人证件
          legalBankLicenseUrl: 3 //  银行开户许可
      },
      legalPersonCardUrl: [], // 法人证件正反面上传结果
    }
  },
  methods: {
      async isValidate () {
          const isValid = await this.$refs.formValidate.validate((valid) => {
                if (!valid) {
                    this.$Message.error('请上传图片!')
                }
            })
          if (this.formValidate.legalPersonCardUrls.length < 2) {
                this.$Message.error('法人证件需按顺序上传正反两面两张图片！')
                return
            }
            return isValid
        },
        handleUpload (file, type) {
            // 法人证件只能上传两张，其余图片只能上传一张
            if (type === this.type.businessLicense && this.formValidate.businessLicense) {
                this.$Message.error('营业执照/三证合一电子版只能上传一张图片，请先删除之前已上传图片')
                return false
            }
            if (type === this.type.legalPersonCardUrl && this.formValidate.legalPersonCardUrls.length === 2) {
                this.$Message.error('法人证件只能上传两张图片，请先删除之前已上传图片')
                return false
            }

            if (type === this.type.legalBankLicenseUrl && this.formValidate.legalBankLicenseUrl) {
                this.$Message.error('法人证件电子版只能上传一张图片，请先删除之前已上传图片')
                return false
            }
            const fileValide = fileUploadLimit(file, this.fileLimitSize)
            if (fileValide) {
                this.$Message.error(fileValide)
                return false
            }
            return true
        },
        uploadSuccess (response, file, fileList, type) {
            if (response.returnCode === '1') {
                if (type === this.type.businessLicense) {
                    this.formValidate.businessLicense = response.result
                    this.$refs.formValidate.validateField('businessLicense')
                }
                if (type === this.type.legalPersonCardUrl) {
                    this.formValidate.legalPersonCardUrls.push({ // 当证件电子照上传成功时，用原file对象的uid作为新返回文件名的key
                        [file.uid]: response.result
                    })
                    this.$refs.formValidate.validateField('legalPersonCardUrls')
                }

                if (type === this.type.legalBankLicenseUrl) {
                    this.formValidate.legalBankLicenseUrl = response.result
                    this.$refs.formValidate.validateField('legalBankLicenseUrl')
                }
            }
        },
        uploadRemove (file, fileList, type) {
            if (type === this.type.businessLicense) {
                this.formValidate.businessLicense = ''
                this.$refs.formValidate.validateField('businessLicense')
            }
            if (type === this.type.legalPersonCardUrl) {
              // 当删除图片时，按照相应文件的uid删除需要返回给后台的对应文件名
                const legalPersonCardUrls = []
                this.formValidate.legalPersonCardUrls.forEach(item => {
                  if (item.uid) {
                    legalPersonCardUrls.push({
                      [item.uid]: item.name
                    })
                  }
                })
                if (legalPersonCardUrls.length) {
                  this.formValidate.legalPersonCardUrls = legalPersonCardUrls
                }
                this.formValidate.legalPersonCardUrls = this.formValidate.legalPersonCardUrls.filter(item => {

                    if (Object.keys(item)[0] === String(file.uid)) {
                        return false
                    }
                    return true
                })

                this.$refs.formValidate.validateField('legalPersonCardUrls')
            }
            if (type === this.type.legalBankLicenseUrl) {
                this.formValidate.legalBankLicenseUrl = ''
                this.$refs.formValidate.validateField('legalBankLicenseUrl')
            }
        },
        getFormData () {
            const data = toRaw(this.formValidate)
            // 法人证件按顺序为正反两面
            data.legalPersonCardUrls.forEach((item, index) => {
                if (index === 0) {
                    data.legalPersonCardFrontUrl = item[Object.keys(item)[0]]
                }
                if (index === 1) {
                    data.legalPersonCardBackUrl = item[Object.keys(item)[0]]
                }
            })
            return data
        }
  },
  watch: {
      companyInfo (val) {
        this.formValidate.businessLicense = val.businessLicense
        this.businessLicenseUpload = [{
          name: val.businessLicense.split('/').pop(),
          url: val.businessLicense
        }]

        this.legalPersonCardUpload = [{
          name: val.legalPersonCardFrontUrl.split('/').pop(),
          url: val.legalPersonCardFrontUrl
        }, {
          name: val.legalPersonCardBackUrl.split('/').pop(),
          url: val.legalPersonCardBackUrl
        }]
        try {
          this.formValidate.legalPersonCardUrls = this.legalPersonCardUpload
          this.formValidate.legalPersonCardFrontUrl = this.legalPersonCardUpload[0].name
          this.formValidate.legalPersonCardBackUrl = this.legalPersonCardUpload[1].name
        } catch (e) {
          console.error(e)
        }
      }
    }
}
</script>
